import Logo from "../../presentationals/logo";
import Form from "./form";
import PropTypes from "prop-types";

const ForgotPassword = ({ onSubmit }) => (
  <section className="gradient-form bg-gray-200 h-screen ">
    <div className="container py-12 px-6 m-auto h-full">
      <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
        <div className="block bg-white shadow-lg rounded-lg">
          <div className="lg:flex lg:flex-wrap g-0">
            <div className="px-4 md:px-0">
              <div className="md:p-12 md:mx-6">
                <Logo />
                <Form onSubmit={onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

ForgotPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPassword;

import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Input from "../../presentationals/input";
import Submit from "../../presentationals/submit";

const Form = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { token } = useParams();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="mb-4">Set your new password</p>
      <div className="mb-4">
        <input type="hidden" {...register(`token`)} value={token} />
        <Input
          register={register}
          name="password"
          placeholder="New Password"
          type="password"
          options={{ required: true, minLength: 5 }}
          hasError={errors.password}
          labelError="Define a valid password"
        />
      </div>
      <div className="mb-4">
        <Input
          register={register}
          name="confirmPassword"
          placeholder="Confirm New Password"
          type="password"
          options={{ required: true, minLength: 5 }}
          hasError={errors.confirmPassword}
          labelError="Match the other password"
        />
      </div>
      <div className="text-center pt-1 mb-8 pb-1">
        <Submit label="Set new password" />
      </div>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Form;

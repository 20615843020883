import { connect } from "react-redux";
import Signup from "./signup";
import { signUpRequested } from "../../store/authentication/authentication-duck";

const mapStateToProps = ({ authentication }) => ({
  isAuthenticating: authentication.isAuthenticating,
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = {
  onSubmit: signUpRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

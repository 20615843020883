import { request } from "../helpers/request";

export const signIn = (payload) => {
  return request(
    `${process.env.REACT_APP_AUTHENTICATION_API}/api/signin`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true
  );
};

export const signUp = (payload) => {
  return request(
    `${process.env.REACT_APP_AUTHENTICATION_API}/api/signup`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true
  );
};

export const forgotPassword = (payload) => {
  return request(
    `${process.env.REACT_APP_AUTHENTICATION_API}/api/forgot-password`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true
  );
};

export const recoverPassword = (payload) => {
  return request(
    `${process.env.REACT_APP_AUTHENTICATION_API}/api/recover-password`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    true
  );
};

import PropTypes from "prop-types";
import classNames from "classnames";

const Input = ({
  register,
  type,
  name,
  placeholder,
  options,
  hasError,
  labelError,
}) => (
  <>
    <input
      type={type}
      {...register(name, options)}
      placeholder={placeholder}
      className={classNames(
        hasError
          ? "border-red-300 focus:border-red-600"
          : "border-gray-300 focus:border-blue-600",
        "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none"
      )}
    />
    {hasError && <span className="text-xs text-red-700">{labelError}</span>}
  </>
);

Input.propTypes = {
  register: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.object,
  hasError: PropTypes.bool,
  labelError: PropTypes.string,
};

Input.defaultProps = {
  hasError: false,
};

export default Input;

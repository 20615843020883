import PropTypes from "prop-types";

const Submit = ({ label, isLoading }) => (
  <input
    disabled={isLoading}
    className="cursor-pointer inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
    type="submit"
    style={{
      background: "linear-gradient(to right, #b2afe8, #8caae5)",
    }}
    value={label}
  />
);

Submit.propTypes = {
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

Submit.defaultProps = {
  isLoading: false,
};

export default Submit;

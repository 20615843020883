import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Logo from "../../presentationals/logo";
import Form from "./form";

const RecoverPassword = ({ onSubmit, isPasswordRecovered }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isPasswordRecovered) {
      return navigate("/");
    }
  }, [isPasswordRecovered]);

  return (
    <section className="gradient-form bg-gray-200 h-screen ">
      <div className="container py-12 px-6 m-auto h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className="block bg-white shadow-lg rounded-lg">
            <div className="lg:flex lg:flex-wrap g-0">
              <div className="px-4 md:px-0">
                <div className="md:p-12 md:mx-6">
                  <Logo />
                  <Form onSubmit={onSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

RecoverPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isPasswordRecovered: PropTypes.bool.isRequired,
};

export default RecoverPassword;

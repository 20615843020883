import React from "react";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createRoot } from "react-dom/client";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import rootReducer from "./store";
import { saga } from "./store/sagas";
import App from "./pages/app";
import Signin from "./pages/signin";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import RecoverPassword from "./pages/recover-password";
import PrivateRoute from "./helpers/privateRoute";

import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

const isProduction = process.env.NODE_ENV === "production";

const logMiddleware = createLogger({
  predicate: () => !isProduction,
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunkMiddleware, sagaMiddleware, logMiddleware];
const store = configureStore({
  reducer: rootReducer,
  devTools: !isProduction,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

sagaMiddleware.run(saga);

// eslint-disable-next-line no-undef
const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/recover-password/:token"
          element={<RecoverPassword />}
        />
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<App />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <ToastContainer />
  </Provider>
);

import fetch from "cross-fetch";
import Cookies from "universal-cookie";

export const request = (url, options, preventToRedirect = false) =>
  new Promise((resolve, reject) => {
    const cookies = new Cookies();
    const box2boxToken = cookies.get("box2boxToken");

    options.mode = "cors";
    options.headers = {
      "Content-Type": "application/json",
    };

    if (box2boxToken) {
      options.headers.Authorization = `Bearer ${box2boxToken.token}`;
    }

    fetch(url, options)
      .then((response) => {
        if (
          !preventToRedirect &&
          (response.status === 401 || response.status === 403)
        ) {
          cookies.remove("box2boxToken");
          reject("Not authorized");
        }
        return response.json();
      })
      .then((json) => {
        resolve(json);
      })
      .catch((err) => reject(err));
  });

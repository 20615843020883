import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { Navigate, Outlet } from "react-router-dom";
import { restoreAuthenticationRequested } from "../store/authentication/authentication-duck";

const PrivateRoute = ({ restoreAuthenticationRequested }) => {
  const cookies = new Cookies();
  const hasCookie = cookies.get("box2boxToken");

  if (hasCookie) {
    restoreAuthenticationRequested();
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to signin page
  return hasCookie ? <Outlet /> : <Navigate to="/signin" />;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  restoreAuthenticationRequested,
};

PrivateRoute.propTypes = {
  restoreAuthenticationRequested: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

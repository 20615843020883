export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const RESTORE_AUTHENTICATION_REQUEST = "RESTORE_AUTHENTICATION_REQUEST";
export const RESTORE_AUTHENTICATION_SUCCESS = "RESTORE_AUTHENTICATION_SUCCESS";
export const RESTORE_AUTHENTICATION_FAILED = "RESTORE_AUTHENTICATION_FAILED";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_FAILED = "RECOVER_PASSWORD_FAILED";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isPasswordRecovered: false,
  id: null,
  name: null,
  isAdmin: false,
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
        payload: action.payload,
      };
    case SIGN_IN_FAILED:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        payload: action.payload,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        payload: action.payload,
      };
    case RESTORE_AUTHENTICATION_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
      };
    case RESTORE_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        payload: action.payload,
      };
    case RESTORE_AUTHENTICATION_FAILED:
      return {
        ...state,
        isAuthenticating: false,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    case SIGN_UP_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
        payload: action.payload,
      };
    case SIGN_UP_FAILED:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        payload: action.payload,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        payload: action.payload,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        payload: action.payload,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        payload: action.payload,
      };
    case RECOVER_PASSWORD_REQUEST:
      return {
        isPasswordRecovered: false,
        ...state,
      };
    case RECOVER_PASSWORD_FAILED:
      return {
        ...state,
        isPasswordRecovered: false,
        payload: action.payload,
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordRecovered: true,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export const signInRequested = (payload) => ({
  type: SIGN_IN_REQUEST,
  payload,
});

export const signInSucceeded = (payload) => ({
  type: SIGN_IN_SUCCESS,
  payload,
});

export const signInFailed = (error) => ({
  type: SIGN_IN_FAILED,
  payload: error,
});

export const restoreAuthenticationRequested = () => ({
  type: RESTORE_AUTHENTICATION_REQUEST,
});

export const restoreAuthenticationSucceeded = (payload) => ({
  type: RESTORE_AUTHENTICATION_SUCCESS,
  payload,
});

export const restoreAuthenticationFailed = () => ({
  type: RESTORE_AUTHENTICATION_FAILED,
});

export const signOutRequested = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signUpRequested = (payload) => ({
  type: SIGN_UP_REQUEST,
  payload,
});

export const signUpSucceeded = (payload) => ({
  type: SIGN_UP_SUCCESS,
  payload,
});

export const signUpFailed = (error) => ({
  type: SIGN_UP_FAILED,
  payload: error,
});

export const forgotPasswordRequested = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordSucceeded = (payload) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailed = (error) => ({
  type: FORGOT_PASSWORD_FAILED,
  payload: error,
});

export const recoverPasswordRequested = (payload) => ({
  type: RECOVER_PASSWORD_REQUEST,
  payload,
});

export const recoverPasswordSucceeded = (payload) => ({
  type: RECOVER_PASSWORD_SUCCESS,
  payload,
});

export const recoverPasswordFailed = (error) => ({
  type: RECOVER_PASSWORD_FAILED,
  payload: error,
});

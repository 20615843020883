import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

import {
  forgotPassword,
  recoverPassword,
  signIn,
  signUp,
} from "../../api/authentication";
import {
  signInFailed,
  signInSucceeded,
  restoreAuthenticationFailed,
  restoreAuthenticationSucceeded,
  signUpSucceeded,
  signUpFailed,
  forgotPasswordSucceeded,
  forgotPasswordFailed,
  recoverPasswordSucceeded,
  recoverPasswordFailed,
  RESTORE_AUTHENTICATION_REQUEST,
  SIGN_IN_FAILED,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_REQUEST,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILED,
} from "./authentication-duck";
import { setAuthCookie } from "../../helpers/setAuthCookie";

function* signInRequest(action) {
  try {
    const { token, email, id, error, message } = yield call(
      signIn,
      action.payload
    );
    if (error) {
      yield put(signInFailed(message));
      return;
    }

    yield put(signInSucceeded({ token, email, id }));
  } catch (e) {
    yield put(signInFailed(e));
  }
}

function signInSuccess(action) {
  const cookies = new Cookies();
  const { id, token, email } = action.payload;

  cookies.set("box2boxToken", setAuthCookie(token, id, email), {
    path: "/",
  });
}

function signInFail(action) {
  toast.error(action.payload);
}

function* restoreAuthenticationRequest() {
  try {
    const cookies = new Cookies();
    const cookie = cookies.get("box2boxToken");

    if (cookie) {
      const { token, email, id } = cookie;
      yield put(signInSucceeded({ token, email, id }));
      yield call(restoreAuthenticationSucceeded);
    }
  } catch (e) {
    yield put(restoreAuthenticationFailed(e));
  }
}

function signOutSuccess() {
  const cookies = new Cookies();
  cookies.remove("box2boxToken", { path: "/" });
}

function* signUpRequest(action) {
  try {
    const { token, email, id, error, message } = yield call(
      signUp,
      action.payload
    );
    if (error) {
      yield put(signUpFailed(message));
      return;
    }

    yield put(signUpSucceeded({ token, email, id }));
  } catch (e) {
    yield put(signUpFailed(e));
  }
}

function signUpSuccess(action) {
  const cookies = new Cookies();
  const { id, token, email } = action.payload;

  cookies.set("box2boxToken", setAuthCookie(token, id, email), {
    path: "/",
  });
}

function signUpFail(action) {
  toast.error(action.payload);
}

function* forgotPasswordRequest(action) {
  try {
    const { error, message } = yield call(forgotPassword, action.payload);
    if (error) {
      yield put(forgotPasswordFailed(message));
      return;
    }

    yield put(forgotPasswordSucceeded(message));
  } catch (e) {
    yield put(forgotPasswordFailed(e));
  }
}

function forgotPasswordSuccess(action) {
  toast.success(action.payload);
}
function forgotPasswordFail(action) {
  toast.error(action.payload);
}

function* recoverPasswordRequest(action) {
  try {
    const { error, message } = yield call(recoverPassword, action.payload);
    if (error) {
      yield put(recoverPasswordFailed(message));
      return;
    }

    yield put(recoverPasswordSucceeded(message));
  } catch (e) {
    yield put(recoverPasswordFailed(e));
  }
}

function recoverPasswordSuccess(action) {
  toast.success(action.payload);
}
function recoverPasswordFail(action) {
  toast.error(action.payload);
}

function* authenticationSaga() {
  yield takeEvery(SIGN_IN_REQUEST, signInRequest);
  yield takeEvery(SIGN_IN_SUCCESS, signInSuccess);
  yield takeEvery(SIGN_IN_FAILED, signInFail);
  yield takeEvery(SIGN_OUT_SUCCESS, signOutSuccess);
  yield takeEvery(SIGN_UP_REQUEST, signUpRequest);
  yield takeEvery(SIGN_UP_SUCCESS, signUpSuccess);
  yield takeEvery(SIGN_UP_FAILED, signUpFail);
  yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
  yield takeEvery(FORGOT_PASSWORD_SUCCESS, forgotPasswordSuccess);
  yield takeEvery(FORGOT_PASSWORD_FAILED, forgotPasswordFail);
  yield takeEvery(RECOVER_PASSWORD_REQUEST, recoverPasswordRequest);
  yield takeEvery(RECOVER_PASSWORD_SUCCESS, recoverPasswordSuccess);
  yield takeEvery(RECOVER_PASSWORD_FAILED, recoverPasswordFail);
  yield takeEvery(RESTORE_AUTHENTICATION_REQUEST, restoreAuthenticationRequest);
}

export default authenticationSaga;

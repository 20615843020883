import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../presentationals/input";
import Submit from "../../presentationals/submit";

const Form = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="mb-4">Recover your password using your email</p>
      <div className="mb-4">
        <Input
          register={register}
          name="email"
          placeholder="E-mail"
          type="email"
          options={{ required: true }}
          hasError={errors.email}
          labelError="Please type a valid e-mail"
        />
      </div>
      <div className="text-center pt-1 mb-8 pb-1">
        <Submit label="Recover password" />
      </div>
      <div className="text-center pt-1 pb-1">Or:</div>
      <div className="flex items-center justify-between pb-6">
        <Link
          to="/signin"
          className="inline-block px-6 py-2 border-2 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Sign in
        </Link>
        <Link
          to="/signup"
          className="inline-block px-6 py-2 border-2 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Sign up
        </Link>
      </div>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Form;

import { connect } from "react-redux";
import RecoverPassword from "./recover-password";
import { recoverPasswordRequested } from "../../store/authentication/authentication-duck";

const mapStateToProps = ({ authentication }) => ({
  isPasswordRecovered: authentication.isPasswordRecovered,
});

const mapDispatchToProps = {
  onSubmit: recoverPasswordRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);

import { connect } from "react-redux";
import ForgotPassword from "./forgot-password";
import { forgotPasswordRequested } from "../../store/authentication/authentication-duck";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onSubmit: forgotPasswordRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

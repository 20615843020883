import { connect } from "react-redux";
import Signin from "./signin";
import { signInRequested } from "../../store/authentication/authentication-duck";

const mapStateToProps = ({ authentication }) => ({
  isAuthenticating: authentication.isAuthenticating,
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = {
  onSubmit: signInRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);

import { useNavigate } from "react-router-dom";

import Logo from "../../presentationals/logo";
import Form from "./form";
import Ad from "./ad";
import PropTypes from "prop-types";
import { useEffect } from "react";

const Signin = ({ onSubmit, isAuthenticating, isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <section className="gradient-form bg-gray-200 h-screen">
      <div className="container py-12 px-6 md:m-auto h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className="xl:w-10/12">
            <div className="block bg-white shadow-lg rounded-lg">
              <div className="lg:flex lg:flex-wrap g-0">
                <div className="lg:w-6/12 px-4 md:px-0">
                  <div className="md:p-12 md:mx-6">
                    <Logo />
                    <Form
                      onSubmit={onSubmit}
                      isAuthenticating={isAuthenticating}
                    />
                  </div>
                </div>
                <Ad />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Signin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Signin;

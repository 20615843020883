const Ad = () => (
  <div
    className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none"
    style={{
      background: "linear-gradient(to right, #b2afe8, #8caae5)",
    }}
  >
    <div className="text-white px-4 py-6 md:p-12 md:mx-6">
      <h4 className="text-xl font-semibold mb-6">Who we are?</h4>
      <p className="text-sm">
        We are passionate about football and fantasy, and we realised that there
        is no good fantasy system to play. So we decided to build a good one,
        with features which we believe that makes the game nicer and funnier.
        Come and join us.
      </p>
    </div>
  </div>
);

export default Ad;

import { connect } from "react-redux";
import Header from "./header";
import { signOutRequested } from "../../store/authentication/authentication-duck";

const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = {
  onSignOut: signOutRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

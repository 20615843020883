import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Input from "../../presentationals/input";
import Submit from "../../presentationals/submit";
import { Link } from "react-router-dom";

const Form = ({ onSubmit, isAuthenticating }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="mb-4">Sign up for a new account</p>
      <div className="mb-4">
        <Input
          register={register}
          name="name"
          placeholder="Name"
          type="name"
          options={{ required: true }}
          hasError={errors.name}
          labelError="Please type a valid name"
        />
      </div>
      <div className="mb-4">
        <Input
          register={register}
          name="email"
          placeholder="E-mail"
          type="email"
          options={{ required: true }}
          hasError={errors.email}
          labelError="Please type a valid e-mail"
        />
      </div>
      <div className="mb-4">
        <Input
          register={register}
          name="password"
          placeholder="Password"
          type="password"
          options={{ required: true, minLength: 5 }}
          hasError={errors.password}
          labelError="Please type a valid password"
        />
      </div>
      <div className="text-center pt-1 mb-12 pb-1">
        <Submit label="Sign up" isLoading={isAuthenticating} />
      </div>
      <div className="flex items-center justify-between pb-6">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p className="mb-0 mr-2">Do you have an account?</p>
        <Link
          to="/signin"
          className="inline-block px-6 py-2 border-2 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Sign in
        </Link>
      </div>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
};

export default Form;
